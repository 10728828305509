exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-members-js": () => import("./../../../src/pages/members.js" /* webpackChunkName: "component---src-pages-members-js" */),
  "component---src-templates-commission-jsx": () => import("./../../../src/templates/commission.jsx" /* webpackChunkName: "component---src-templates-commission-jsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/home-page.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-news-jsx": () => import("./../../../src/templates/news.jsx" /* webpackChunkName: "component---src-templates-news-jsx" */),
  "component---src-templates-news-list-template-js": () => import("./../../../src/templates/news-list-template.js" /* webpackChunkName: "component---src-templates-news-list-template-js" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-position-jsx": () => import("./../../../src/templates/position.jsx" /* webpackChunkName: "component---src-templates-position-jsx" */),
  "component---src-templates-publications-list-template-js": () => import("./../../../src/templates/publications-list-template.js" /* webpackChunkName: "component---src-templates-publications-list-template-js" */),
  "slice---src-components-footer-index-jsx": () => import("./../../../src/components/Footer/index.jsx" /* webpackChunkName: "slice---src-components-footer-index-jsx" */),
  "slice---src-components-header-index-jsx": () => import("./../../../src/components/Header/index.jsx" /* webpackChunkName: "slice---src-components-header-index-jsx" */)
}

